import React, { useState } from 'react';
import '../../styles/Start.css';

const Start = (props) => {
  // State for storing email input value
  const [email, setEmail] = useState('');
  // State for storing whether the form is submitting
  const [isSubmitting, setIsSubmitting] = useState(false);
  // State for storing whether the form is valid
  const [isValid, setIsValid] = useState(false);
  const[firstLoad,setFirstLoad] = useState(true);

  const regexEmailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Set form submission flag to true
  
    // Perform form validation
    if (email.trim() !== '' &&  
      regexEmailValidation.test(String(email).toLowerCase())) {
      // Email is not empty, consider form as valid
      setIsSubmitting(true);
      setIsValid(true);
      // Perform any additional actions here, like submitting data to a server
      localStorage.setItem("email", email);
  
      fetchData(email); // Pass email as an argument to fetchData
      // window.location.assign("");
    } else {
      // Email is empty, consider form as invalid
      setIsValid(false);
      setFirstLoad(false);
      setIsSubmitting(false); 
    }
  };
  
  const fetchData = async (email) => {

    localStorage.setItem("returnUrl", window.location.href);

    generateCodeChallenge().then((code_challenge) => {
    const qParams = new URLSearchParams({
      redirect_uri: process.env.REACT_APP_BASE_NCA_Redirect_URI,
      scope: "openid profile email nca",
      client_id: process.env.REACT_APP_BASE_NCA_ClientId,
      response_type: "code",
      nonce: window.crypto.getRandomValues(new Uint8Array(32)),
      state: "returnUrl",
      code_challenge: code_challenge,
      code_challenge_method: "S256",
      login_hint: email,
    });
    try {
      const authurl = process.env.REACT_APP_BASE_NCA_AUTH_URL;
      const url = `${authurl}?${qParams}`;
      window.location.assign(url);
      
    } catch (e) {
      console.error(e);
      localStorage.setItem("IsUserLoggedIn", "false");
    }
    });
  }

  // Handler for updating email input value
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const b64url = (array) => {
    const b64 = btoa(String.fromCharCode.apply(null, array));
    return b64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  };

  const generateCodeChallenge = async () => {
    // Generate a 32-octet random sequence
    const rand = window.crypto.getRandomValues(new Uint8Array(32));

    // The PKCE code verifier is the base64url encoding of the random octets
    const codeVerifier = b64url(rand);
    localStorage.setItem("CV", codeVerifier);

    // Calculate the SHA-256 hash of the code verifier
    const data = new TextEncoder().encode(codeVerifier);
    var sha256Hash = await window.crypto.subtle.digest("SHA-256", data);

    // The PKCE code challenge is the base64url encoding of the hash
    return b64url(new Uint8Array(sha256Hash));
  };

  return (
    <div className='signbox'> 
    <div className="login-container">
      <div className="login-box">
        <div className="sign-in-rectangle-15"></div>
        <div className="sign-in-heading">
          <div className="sign-in-heading-medium">Let’s get started</div>
        </div>
        <br />
        <div className="sign-in-frame-41">
          <div className="sign-in-text-input">
            <div className="sign-in-text-input2">
              <input
                type="text"
                id="email"
                name="email"
                className="sign-in-text-input sign-in-text-input2 sign-in-input sign-in-input-contents sign-in-input-text sign-in-input-label sign-in-label"
                placeholder="Please enter email"
                value={email}
                onChange={handleEmailChange}
                disabled={isSubmitting}
                style={{width:"auto"}}
              />
              {/* You can add your own error message display logic here */}
              {/* For example: */}
              {!isValid  && !firstLoad && (
                <div className="errorMsg">Please enter a valid email</div>
              )}
            </div>
          </div>
        </div>

        <div className="login-button-container">
          <div className="box">
            <button
              className="sign-in-cta-button sign-in-cta-button-primary2"
              type="submit"
              onClick={handleSubmit}
              //disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? 'Submitting...' : 'Sign In'}
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Start;
