import React from 'react'

const Footer = props => {
  return (
    <footer className={'Footer'}>
      Copyright © {new Date().getFullYear()} NVIDIA Corporation
    </footer>
  )
}

export default Footer
