import Config from "./config.json";
import ConfigOctopus from "./config.octopus.json";

const ConfigManager = () => {
  if (ConfigOctopus.TermsLink.indexOf("#{") > -1) {
    return Config;
  } else {
    return ConfigOctopus;
  }
};

export default ConfigManager;
