import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../../images/logo.png'

const AppHeader = props => {
  return (
    <header className='AppHeader'>
      <div className='centered'>
        <div className='AppLogoContainer'>
          <img className='AppLogo' src={Logo} alt='NVIDIA Logo' />
        </div>
      </div>
      <div style={{ background: '#000' }}>
        <div className='centered'>
          <div className='AppBackground'>
            <h1>{props.bannerText}</h1>
          </div>
        </div>
      </div>
    </header>
  )
}

AppHeader.propTypes = {
  bannerText: PropTypes.string
}

export default AppHeader
