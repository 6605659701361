import React from "react";
import SectionHeader from "./SectionHeader";
import Input from "../util/Input";
import { PropTypes } from "prop-types";

const Entitlement = props => {
  return (
    <div className='AppSection'>
      <SectionHeader title={"Entitlement"} showHr={false} />
      <div className={"leftColumnOffset"}>
        {
          <div>
            <Input
              labelText='PAK ID/Entitlement ID'
              type='text'
              value={props.pakId}
              readOnly={!props.allowPakIdEdit}
              onChange={props.onPakIdChange}
              isRequired={false}
              onBlur={props.onPakIdBlur}
              maximumLength={35}
            />
            {props.showSerial && <div className='orChad'>OR</div>}
          </div>
        }
        {props.showSerial && (
          <div>
            <Input
              labelText='Serial Number'
              type='text'
              value={props.serial}
              onChange={props.onSerialChange}
              readOnly={!props.allowSerialEdit}
              isRequired={false}
              onBlur={props.onSerialBlur}
              maximumLength={18}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Entitlement.propTypes = {
  showSerial: PropTypes.bool.isRequired,
  pakId: PropTypes.string,
  serial: PropTypes.string,
  allowPakIdEdit: PropTypes.bool.isRequired,
  allowSerialEdit: PropTypes.bool.isRequired,
  onPakIdChange: PropTypes.func.isRequired,
  onSerialChange: PropTypes.func.isRequired,
  onPakIdBlur: PropTypes.func.isRequired,
  onSerialBlur: PropTypes.func.isRequired
};

export default Entitlement;
