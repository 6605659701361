import React from 'react'
import AppHeader from './AppHeader'

const Error = () => {
  return (
    <div>
          <AppHeader bannerText='NVIDIA Enterprise Account Registration' />
      <div className='error-content'>
        <h2>Something went wrong while generating the page.</h2>
      </div>
    </div>
  )
}

export default Error
