import React from "react";
import SectionHeader from "./SectionHeader";
import Input from "../util/Input";
import Select from "../util/Select";
import { PropTypes } from "prop-types";
import HelpBlock from "./HelpBlock";

const CompanyInfo = props => {
  const ceSelected = props.claimingEntitlementAs !== 0;

  return (
    <div className='AppSection'>
      <SectionHeader title={"Company"} />
      {ceSelected && (
        <HelpBlock claimingEntitlementAs={props.claimingEntitlementAs} />
      )}

      <div className='leftColumn'>
        <Input
          labelText={"Company Name"}
          isRequired={true}
          value={props.orgData.CompanyName}
          readOnly={props.isOrgLock}
          onChange={props.handleCompanyNameChange}
          maximumLength={160}
        />
        <Select
        
          labelText={"Location"}
          optionValues={props.countries}
          isRequired={true}
          value={props.orgData.CompanyCountry}
          onChange={props.handleCompanyCountryChange}
          displayFieldName='countryName'
          valueFieldName='id'
        />
        <Input
          labelText={"Street 1"}
          isRequired={true}
          onChange={props.handleStreet1Change}
          maximumLength={40}
        />
        <Input
          labelText={"Street 2"}
          onChange={props.handleStreet2Change}
          maximumLength={40}
        />
        <Input
          labelText={"City"}
          isRequired={true}
          onChange={props.handleCityChange}
          maximumLength={40}
        />
        <Select
          labelText={"State"}
          optionValues={props.states}
          isRequired={true}
          value={props.orgData.CompanState}
          onChange={props.handleCompanyStateChange}
          displayFieldName='stateName'
          valueFieldName='id'
          readOnly={props.states[0].id === -1}
        />
        <Input
          labelText={"Postal Code/Zip Code"}
          isRequired={true}
          onChange={props.handleZipChange}
          maximumLength={10}
        />

        <Select
          labelText={"Industry"}
          optionValues={props.industryOptions}
          defaultValue={"Please choose one"}
          isRequired={true}
          value={props.orgData.CompanyIndustry}
          onChange={props.handleCompanyIndustryChange}
        />
      </div>
    </div>
  );
};

CompanyInfo.propTypes = {
  orgData: PropTypes.object,
  isOrgLock: PropTypes.bool.isRequired,
  pecProvided: PropTypes.bool.isRequired,
  handleCompanyNameChange: PropTypes.func.isRequired,
  handleCompanyCountryChange: PropTypes.func.isRequired,
  handleCompanyStateChange: PropTypes.func.isRequired,
  handleCompanyIndustryChange: PropTypes.func.isRequired,
  claimingEntitlementAs: PropTypes.number,
  handleStreet1Change: PropTypes.func.isRequired,
  handleStreet2Change: PropTypes.func.isRequired,
  handleCityChange: PropTypes.func.isRequired,
  handleZipChange: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  industryOptions: PropTypes.array.isRequired
};

export default CompanyInfo;
