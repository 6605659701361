import React from "react";
import { PropTypes } from "prop-types";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MessageCenter = props => {
  let list = props.messages.map((m, i) => {
    return <p key={i}>{m}</p>;
  });

  return (
    <div className="messages">
      {/* {props.showIcon && (
        <div>
          <FontAwesomeIcon icon="spinner" spin color="Green" />
        </div>
      )} */}
      {list}
      {props.showUserInput && (
        <div className="messages-actions">
          <button onClick={props.onYesClick} className="hero">
            yes
          </button>
          <button onClick={props.onNoClick}>no</button>
        </div>
      )}
    </div>
  );
};

MessageCenter.propTypes = {
  messages: PropTypes.array.isRequired,
  showIcon: PropTypes.bool,
  showUserInput: PropTypes.bool,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func
};

MessageCenter.defaultProps = {
  showIcon: true
};
export default MessageCenter;
