import React from "react";
import { PropTypes } from "prop-types";

const InfoBlock = (props) => {
  const hasTitle = props.title !== undefined && props.title !== "";
  const IsRWA03 = props.IsRWA03;
  const IsRWA02 = props.IsRWA02;
  
  if (IsRWA03) {
    return (
      <div className={"infoBlock"}>
        <div className={"infoBlockEval"}>
          {hasTitle && <h3>{props.title}</h3>}
          <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            <li>Evaluation accounts can only have 1 user.* </li>
            <li>
              Please ensure that the person who is registering for the
              evaluation licenses is the person who will be managing those
              licenses.
            </li>
            <li>
              {" "}
              If already registered, click <a href={props.idmLogin}>here</a>
            </li>
            <li>
              If you need assistance with registration or are seeking an
              exception, please review{" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/nvidia-enterprise-account-faq/'
              >
                {" "}
                FAQ{" "}
              </a>{" "}
              .
            </li>
          </ul>
          *This limitation does not apply to customers who have commercial
          entitlements.
        </div>
      </div>
    );
  }
  else if(IsRWA02)
  {
   return (
     <div className={"infoBlock"}>
       {hasTitle && <h3>{props.title}</h3>}
       <p>Please register with your corporate email address.</p>
       If you need assistance or have questions with account registration,
       please review the Registration{" "}
       <a rel='noopener noreferrer' target='_blank' href={props.link}>
          FAQ
       </a>
       .
     </div>
   );
  }
  else {
    return (
      <div className={"infoBlock"}>
        {hasTitle && <h3>{props.title}</h3>}
        <p>Please register with your corporate email address.</p>
        If you need assistance or have questions with account registration,
        please review Registration{" "}
        <a rel='noopener noreferrer' target='_blank' href={props.link}>
          {props.linkText}
        </a>
        .
      </div>
    );
  }
};


InfoBlock.propTypes = {
  title: PropTypes.string,
  IsRWA03: PropTypes.bool.isRequired,
  IsRWA02: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  idmLogin: PropTypes.string.isRequired,
};

export default InfoBlock;
