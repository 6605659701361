import React from "react";
import ReactDOM from "react-dom";
import Creatable from "react-select/creatable";
import { components } from "react-select";
import { PropTypes } from "prop-types";
import Select from 'react-select';


const customStyles = {
  control: (base, state) => ({
    ...base,
   

    background: "white",
    boxShadow:"0 0 0px",
    // match with the menu
  //  borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    outline: "0",
    borderWidth: "0 0 0px",
    
    // Removes weird border around container
    // boxShadow: state.isFocused ? null : null,
     "&:hover": {
      // Overwrittes the different states of border
      boxShadow:"0 0 0px",
      outline: "0",
      borderWidth: "0 0 2px",
      borderColor: "#76b900",
      maxHeight:"100px",
     }
  }),
  menu: base => ({
    ...base,
    // "&:hover": {
    //   color: "red"
    // },
    boxShadow:"0 0 1px",
    borderleft: "1px solid!important",
    background:"#f2f2f2",
    "&:hover": {
      boxShadow:"0 0 1px",
      // Overwrittes the different states of border
      boxShadow:"0 0 0px",
      outline: "0",
      borderWidth: "0 0 2px",
      borderColor: "#76b900",
     }
    // override border radius to match the box
   // borderRadius: 0,
    // kill the gap
   // marginTop: 0
  }),
  menuList: base => ({
    ...base,
    "&:hover": {
      // Overwrittes the different states of border
      boxShadow:"0 0 1px",
      outline: "0",
      borderWidth: "0 0 2px",
      borderColor: "#76b900",
     },
    background:"#f2f2f2",
    fontSize :".80em!important"

    // kill the white space on first and last option
   // padding: 0
  }),
  option: base => ({
    ...base,
    textAlign:"left",
    "&:hover": {
      background:"#007bff!important",
      color:"white",
     
    }
  }),
};

const Menu = props => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props} >
      {optionSelectedLength < 3 ? (
        props.children
      ) : (
        <div style={{ margin: 15 }}>Max limit achieved</div>
      )}
    </components.Menu>
  );
};

const MulSelectCreatable = props => {
    const error = props.isRequired ? "required" : ""
  const classes = `mulSelectStyles ${error}`
    let res=[];
  for (var i = 0; i < props.optionValues.length; ++i) {
    
      res.push({"value": props.optionValues[i].key  , "label" :  props.optionValues[i].value});
    }
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 3;
  return (
    <div className={"selectDiv"}>
        <label className={classes}>
        {props.labelText}:{" "}
    {  <Select
   styles={customStyles}
    menuPlacement="top"
    allowCreateWhileLoading={false}
    onChange={props.onChange}
        components={{ Menu }}
        placeholder={<div>Select (1-3) Options</div>}
        isMulti
        isValidNewOption={isValidNewOption}
        options={res}
        
      />}
       </label>
    </div>
  );
}
MulSelectCreatable.propTypes = {
    optionValues: PropTypes.array.isRequired,
    isRequired: PropTypes.bool,
    labelText: PropTypes.string.isRequired,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
  
    readOnly: PropTypes.bool,
    displayFieldName: PropTypes.string,
    valueFieldName: PropTypes.string
  };
  Menu.propTypes = {
  getValue: PropTypes.func.isRequired,
  children:PropTypes.array.isRequired,
  }
export default MulSelectCreatable;
