import React from "react";
import PropTypes from "prop-types";
import SectionHeader from "./SectionHeader";
import Input from "../util/Input";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,faTimes,faSync
 
} from "@fortawesome/free-solid-svg-icons";
import Select from "../util/Select";

const PrimaryContact = (props) => {
  return (
    <div className='AppSection'>
      <SectionHeader title={"Primary Contact"} showHr={!props.IsRWA03} />

      <div className={"leftColumn"}>
        <Input
          labelText='First Name'
          isRequired={true}
          onChange={props.handleFirstNameChange}
          value={props.firstName}
          readOnly={props.lockFirstName}
          maximumLength={35}
        />
        <Input
          labelText='Last Name'
          isRequired={true}
          onChange={props.handleLastNameChange}
          value={props.lastName}
          readOnly={props.lockLastName}
          maximumLength={35}
        />
        <Input
          labelText='Email Address'
          isRequired={true}
          onChange={props.handleEmailChange}
          onBlur={props.handleEmailBlur}
          value={props.emailAddress}
          readOnly={props.lockEmail}
          maximumLength={80}
          
          
        /> 
        </div>
        <div className={"leftColumn"} style={{width:"auto"}}>
          
        { (props.emailValidationCheck) &&
 <FontAwesomeIcon icon={faCheck}style={{color: "#76b900"}}/>
      }
       {(props.emailValidationWrong) &&
 <FontAwesomeIcon icon={faTimes} />
      }
        {(props.emailValidationLoading) &&
 <FontAwesomeIcon className={"fa-spin"}  style={{color: "#76b900"}}icon={faSync}/>
      }
          
          </div>
        <div>
        <div className={"leftColumn"}>
        <Input
          labelText='Confirm Email Address'
          isRequired={true}
          onChange={props.handleEmailChangeConfirm}
          value={props.emailAddressConfirm}
          readOnly={props.lockEmail}
          maximumLength={80}
          onPaste={props.handleEmailConfirmPaste}
        />
        </div>
      </div>
     
      {props.IsRWA03 === false && (
        <div>
          <div className={"leftColumn"}>
            <Select
              id='EntitlementAs'
              labelText='Claiming Entitlement as'
              optionValues={props.claimingEntitlementAsOptions}
              isRequired={true}
              onChange={props.handleClaimingEntitlementAsChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

PrimaryContact.propTypes = {
  IsRWA03: PropTypes.bool.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleEmailChangeConfirm: PropTypes.func.isRequired,
  handleEmailConfirmPaste: PropTypes.func.isRequired,
  handleEmailBlur: PropTypes.func.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleClaimingEntitlementAsChange: PropTypes.func.isRequired,
  emailAddressConfirm: PropTypes.string,
  emailAddress: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  lockEmail: PropTypes.bool.isRequired,
  lockFirstName: PropTypes.bool.isRequired,
  lockLastName: PropTypes.bool.isRequired,
  claimingEntitlementAsOptions: PropTypes.array.isRequired,
  emailValidationLoading: PropTypes.bool,
  emailValidationCheck: PropTypes.bool,
  emailValidationWrong: PropTypes.bool
};

export default PrimaryContact;
