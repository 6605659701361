import React from "react";
import { PropTypes } from "prop-types";

const Select = props => {
  let value = props.valueFieldName ? props.valueFieldName : "key";
  let display = props.displayFieldName ? props.displayFieldName : "value";

  let optionsArray = props.optionValues.map(optionItem => {
    return (
      <option key={optionItem[value]} value={optionItem[value]}>
        {optionItem[display]}
      </option>
    );
  });

  return (
    <div className={"selectDiv"}>
      <label className={props.isRequired ? "required" : ""}style={{ marginLeft: props.isVMWare? '-100px': ''}}>
        {props.labelText}:{" "}
        <select
          id={props.id}
          required={props.isRequired}
          value={props.value}
          onChange={props.onChange}
          disabled={props.readOnly}
          readOnly={props.readOnly}
          
        >
          
          {optionsArray}
        </select>
      </label>
    </div>
  );
};

Select.propTypes = {
  optionValues: PropTypes.array.isRequired,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isVMWare: PropTypes.bool,
  displayFieldName: PropTypes.string,
  valueFieldName: PropTypes.string
};

Select.defaultProps = {
  readOnly: false,
  isVMWare:false
};


export default Select;
