import React from "react";
import HorizontalRule from "../util/HorizontalRule";
import { PropTypes } from "prop-types";
const SectionHeader = props => {
  return (
    <div>
      {props.showHr && <HorizontalRule />}
      <div className={"sectionHeader"}>
        <h3>{props.title}</h3>
      </div>
    </div>
  );
};

SectionHeader.defaultProps = {
  showHr: true
};

SectionHeader.propTypes = {
  showHr: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export default SectionHeader;
