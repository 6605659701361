import React from "react";
import { PropTypes } from "prop-types";

const Input = (props) => {
  return (
    <div className={"textInputDiv"}>
    
      <label data-tip={props.datatip} className={props.isRequired ? "required" : ""}>
        {props.labelText}:{" "}
        <input
        
          id={props.id}
          type={props.type}
          required={props.isRequired}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.readOnly}
          readOnly={props.readOnly}
          value={props.value}
          maxLength={props.maximumLength}
          onPaste={props.onPaste}
        />
      </label>
    </div>
  );
};

Input.propTypes = {
  isRequired: PropTypes.bool,

  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onPaste: PropTypes.func,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  maximumLength: PropTypes.number,
};

Input.defaultProps = {
  type: "text",
  readOnly: false,
  isRequired: false,
};

export default Input;
