import "@babel/polyfill";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import ConfigManager from "./config/configManager";
const configAppInsight = ConfigManager();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: configAppInsight.AppInsightKey,
    autoTrackPageVisitTime: true

    /* ...Other Configuration Options... */
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

ReactDOM.render(<App />, document.getElementById("root"));
