import React from "react";
import SectionHeader from "./SectionHeader";
import Input from "../util/Input";
import Select from "../util/Select";
import { PropTypes } from "prop-types";
import { propTypes } from "react-bootstrap/esm/Image";

const PrimaryContactDetails = props => {
  return (
    <div className='AppSection'>
      <SectionHeader title={"Primary Contact Details"} />
      <div className='sectionInfo2'>
        {!props.pecProvided && (
          <label>
            <input
              type='checkbox'
              checked={!props.useAltAddress}
              onChange={props.handleAltAddressChange}
            />
            Address same as Organization/Company address
          </label>
        )}
      </div>
      <div className={"leftColumn"}>
        {props.useAltAddress ? (
          <Select
            labelText={"Location"}
            optionValues={props.countries}
            isRequired={true}
            value={props.country}
            onChange={props.handleCountryChange}
            readOnly={!props.useAltAddress}
            displayFieldName='countryName'
            valueFieldName='id'
          />
        ) : (
          <Input
            labelText={"Location"}
            isRequired={true}
            value={props.country}
            readOnly={!props.useAltAddress}
          />
        )}
        <Input
          labelText={"Street 1"}
          isRequired={true}
          value={props.street1}
          onChange={props.handleStreet1Change}
          readOnly={!props.useAltAddress}
          maximumLength={40}
        />
        <Input
          labelText={"Street 2"}
          value={props.street2}
          onChange={props.handleStreet2Change}
          readOnly={!props.useAltAddress}
          maximumLength={40}
        />
        <Input
          labelText={"City"}
          isRequired={true}
          value={props.city}
          onChange={props.handleCityChange}
          readOnly={!props.useAltAddress}
          maximumLength={40}
        />

        {props.useAltAddress ? (
          <Select
            labelText={"State/Province"}
            optionValues={props.states}
            isRequired={true}
            value={props.state}
            onChange={props.handleStateChange}
            readOnly={!props.useAltAddress || props.states[0].id === -1}
            displayFieldName='stateName'
            valueFieldName='id'
          />
        ) : (
          <Input
            labelText={"State/Province"}
            isRequired={true}
            value={props.state}
            readOnly={!props.useAltAddress}
          />
        )}
        <Input
          labelText={"Postal Code/Zip Code"}
          isRequired={true}
          value={props.zip}
          onChange={props.handleZipChange}
          readOnly={!props.useAltAddress}
          maximumLength={10}
        />
        <Input
          labelText={"Phone"}
          isRequired={true}
          value={props.phone}
          onChange={props.handlePhoneChange}
          maximumLength={35}
        />
        <Select
          labelText={"Job Role"}
          optionValues={props.jobRoles}
          isRequired={true}
          onChange={props.handleJobRoleChange}
        />
        {props.isEval && (
        <Input
          labelText={"Partner"}
          isRequired={false}
          value={props.partnerValue}
          onChange={props.handlePartnerChange}
          maximumLength={35}
          readOnly={props.partnerValuePresent}
        />)}
      <div className='PartnerInfo'>
      {props.isEval && (
          <label>
            
          Please let us know if you were referred to this trial by a partner.
          </label>
      )}
      </div>
        
      </div>
    </div>
  );
};

PrimaryContactDetails.propTypes = {
  isEval:PropTypes.bool,
  street1: PropTypes.string,
  partnerValuePresent:PropTypes.bool,
  street2: PropTypes.string,
  city: PropTypes.string,
  countries: PropTypes.array,
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  states: PropTypes.array,
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phone: PropTypes.string,
  partnerValue: PropTypes.string,
  jobRoles: PropTypes.array.isRequired,
  handlePartnerChange:PropTypes.func.isRequired,
  handleStreet1Change: PropTypes.func.isRequired,
  handleStreet2Change: PropTypes.func.isRequired,
  handleCityChange: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  handleStateChange: PropTypes.func.isRequired,
  handleZipChange: PropTypes.func.isRequired,
  handlePhoneChange: PropTypes.func.isRequired,
  handleJobRoleChange: PropTypes.func.isRequired,
  pecProvided: PropTypes.bool.isRequired,
  useAltAddress: PropTypes.bool.isRequired,
  handleAltAddressChange: PropTypes.func.isRequired
};

export default PrimaryContactDetails;
