import React from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfigManager from "../../config/configManager";

const HelpBlock = props => {
  const Config = ConfigManager();
  const helpText =
    Config.ClaimingEntitlementHelpText[props.claimingEntitlementAs];

  return (
    <div className="helpBlock">
      <FontAwesomeIcon icon="info-circle" />
      <div className="helpBlockContent">{helpText}</div>
    </div>
  );
};

HelpBlock.propTypes = {
  claimingEntitlementAs: PropTypes.number
};

export default HelpBlock;
